"use client";

import { Page } from "@shopify/polaris";

export default function PageLayoutNoAds({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Page>
      <div className="relative px-2">{children}</div>
    </Page>
  );
}
